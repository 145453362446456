import React from 'react'
import navbar from "../assets/navbar.png"
import navbarmob from "../assets/navbarmob.png"
import logolike from "../assets/logolike.png"
import xicon from "../assets/xicon.png"
import tgicon from "../assets/tgicon.png"
import america from "../assets/america.png"
import dextols from "../assets/dextols.png"

function Navbar() {
    return (
        <div className='z-20 Navbar flex flex-col justify-center items-center relative king'>
            <img src={navbar} alt="navbar" className='w-full lg:w-11/12 hidden lg:block' />
            <img src={navbarmob} alt="navbarmob" className='w-11/12 lg:hidden' />

            <div className="stuff flex flex-row justify-between items-center absolute  w-11/12">

                <div className="ml-20 menu  flex-row gap-5 hidden lg:flex ">
                    <a href="#home" className='text-2xl'>Home</a>
                    <a href="#about" className='text-2xl'>About</a>
                    <a href="#tokenomics" className='text-2xl'>Tokenomics</a>
                </div>

                <a href="#"><img src={logolike} alt="logolike" className='w-60 hidden lg:block' /></a>

                <div className="third flex lg:flex-row flex-row-reverse justify-between items-center  w-full lg:w-auto">
                    <a href="https://app.uniswap.org/explore/tokens/ethereum/0x26b9a6b9b5604c35ac98462f055b9a900a8a58de" target="_blank" rel="noopener noreferrer" className='text-2xl hidden lg:block'>Buy Now</a>
                    <div className="icons flex flex-row gap-5 mx-5 ">
                        <a href="https://t.me/HistoryOfTrump" target="_blank" rel="noopener noreferrer"><img src={tgicon} alt="tgicon" className='w-12' /></a>
                        <a href="https://x.com/historyoftrump_?s=21&t=hFPpzrUiGpyoqrZ5CJ3fwQ" target="_blank" rel="noopener noreferrer"><img src={xicon} alt="xicon" className='w-12' /></a>
                        <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x77ec478a260134c4649c5a7f4b3aea3065df665c?t=1730069184747" target="_blank" rel="noopener noreferrer"><img src={dextols} alt="dextols" className='w-12' /></a>
                    </div>
                    <img src={america} alt="america" className='w-32' />
                </div>
            </div>
        </div>
    )
}

export default Navbar